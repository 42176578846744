<template>
  <div>
    <ion-row>
      <div class="prime-view-container fixed-full-height full-width">
        <ion-row class="display-flex valign margin-top-twenty">
          <ion-col>
            <h4 class="view-title">Tasks</h4>
          </ion-col>
          <ion-col></ion-col>
          <ion-col size="3" v-if="!isPageLoading">
            <div class="form-control-search valign">
              <!-- TODO - the api is not set up to take a search for task parameter yet!  -->
              <input class="full-width" v-model="patientSearchString" placeholder="Search for task..." @change="filter()" @input="debounceSearch($event, 'search')" />
              <IconSearch class="icon-small icon-grey" />
            </div>
          </ion-col>
          <ion-col size="auto" class="margin-lr-ten" v-if="!isPageLoading">
            <select class="form-control" style="min-width:150px;" @change="filterByStatus($event)" v-model="singleStatusFilter">
              <option value="default" disabled hidden selected>Filter by Status</option>
              <option value="all">All Tasks</option>
              <option value="created">New</option>
              <option value="assigned">Assigned</option>
              <option value="in_progress">In Progress</option>
              <option value="canceled">Canceled</option>
              <option value="completed">Completed</option>
            </select>
          </ion-col>
          <ion-col size="2" class="form-control" v-if="!isPageLoading">
            <select :disabled="programs.length == 0" class="form-control" @change="filterByProgram($event)" v-model="programsModel">
              <option value="default" disabled hidden selected>Filter by Program</option>
              <option value="">All Programs</option>
              <option v-for="(program, key) in programs" :key="key" :value="program.id">{{ program.name }}</option>
            </select>
          </ion-col>
        </ion-row>
        <ion-row v-if="!isPageLoading">
          <ion-col></ion-col>
          <ion-col>
            <ion-segment :value="taskMode" mode="ios" class="prime-channel-tabs-container margin-top-twenty">
              <ion-segment-button value="open" class="prime-channel-tab" mode="ios" @click="filterByStatus(openTasksFilters)">
                Open Tasks
              </ion-segment-button>
              <ion-segment-button value="closed" mode="ios" @click="filterByStatus(closedTasksFilters)">
                Closed Tasks
              </ion-segment-button>
              <div class="tabs-divider"></div>
              <ion-segment-button value="all" mode="ios" @click="filterByStatus('all')">
                All
              </ion-segment-button>
            </ion-segment>
          </ion-col>
          <ion-col></ion-col>
        </ion-row>
        <div v-if="isPageLoading" class="prime-tasks-empty-container margin-top-twenty">
          <ion-spinner name="dots"></ion-spinner>
        </div>
        <div v-else-if="!hasTasks" class="prime-tasks-empty-container margin-top-thirty">
          <div v-if="!isTableLoading">
            <IconCheckCircle class="prime-tasks-empty-icon icon-check-circle-watermark" />
            <h5>You currently have no assigned tasks.</h5>
          </div>
          <div v-else class="prime-tasks-empty-container margin-top-twenty">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </div>
        <div v-else class="prime-tasks-section-container">
          <div v-if="isTableLoading" class="text-center pad-ten loading-overlay center-center fade-in margin-top-ten">
            <ion-spinner name="dots"></ion-spinner>
          </div>
          <vue-good-table
            ref="vgt-table"
            mode="remote"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPageDropdown: [10, 20, 30, 40, 50],
              perPage: serverParams.perPage,
              dropdownAllowAll: false
            }"
            :totalRows="totalRecords"
            :columns="columns"
            :rows="rows"
            styleClass="prime-tasks-table vgt-tasks-table"
            :sort-options="{
              enabled: true
            }"
            @on-row-click="onRowClick"
            @on-page-change="onPageChange"
            @on-per-page-change="onPerPageChange"
            @on-sort-change="onSortChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.label == 'Task Title'" style="width: 100%; display: inline-block;">
                <ion-row class="ion-align-items-center">
                  <ion-col size="auto" class="pad-right-twenty">
                    <div class="prime-task-checkbox" :class="{ checked: props.row.task.status == 'completed', checkedGrey: props.row.task.status == 'canceled' }">
                      <div />
                    </div>
                  </ion-col>
                  <ion-col size="10">
                    <div class="text-semibold text-title">{{ props.row.task.name ? props.row.task.name : "N/A" }}</div>
                    <div class="text-small" v-if="props.row.program.name != 'Text Triage'">
                      <span class="text-muted">Program:</span> <span>{{ props.row.program.name ? props.row.program.name : "N/A" }}</span>
                    </div>
                  </ion-col>
                </ion-row>
              </span>
              <span v-if="props.column.label == 'Patient'" class="text-semibold">
                {{ (props.row.patientAccount.lastName ? props.row.patientAccount.lastName + ", " : "") + (props.row.patientAccount.firstName ? props.row.patientAccount.firstName : "") }}
                <span @click="openPatientInfoPopover($event, props.row)">
                  <IconInfoCircle class="form-tooltip-icon" />
                </span>
              </span>
              <span v-else-if="props.column.label == 'Assigned To'">
                {{ props.row.assignedTo ? makeAssignedName(props.row.assignedTo) : "Care Team" }}
              </span>
              <span v-else-if="props.column.label == 'Time Open'" class="text-center text-bold pill" :class="{ 'yellow-pill': showYellowTimeElapsedPill(props.row.task) }">
                <span v-if="props.row.task.createdAt">
                  <TimeSince :startdate="props.row.task.createdAt" :enddate="props.row.task.lastUpdatedAt" :status="props.row.task.status"></TimeSince>
                </span>
                <span v-else>
                  N/A
                </span>
              </span>
              <span v-else-if="props.column.label == 'Status'" class="text-center text-bold pill" :class="{ 'green-pill': props.row.task.status == 'completed', 'yellow-pill': props.row.task.status == 'in_progress', 'blue-pill': props.row.task.status == 'created' || props.row.task.status == 'assigned', 'red-pill': props.row.task.status == 'blocked' }">
                {{ props.row.task.status ? makeStatus(props.row.task.status) : "N/A" }}
              </span>
              <span v-else-if="props.column.label == 'Created'" class="text-small">
                <span v-if="props.row.task.createdAt">{{ props.row.task.createdAt | moment("M/DD/YYYY - h:mmA") }}</span>
                <span v-else>N/A</span>
              </span>
              <span v-else-if="props.column.label == 'Task ID'" class="text-small">
                {{ props.row.task.id ? props.row.task.id : "-" }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </ion-row>
  </div>
</template>

<script>
import _ from "lodash";
import IconCheckCircle from "@/components/Global/Icons/IconCheckCircle";
import GetTasksList from "@/services/Api/getTasksList";
import IconSearch from "@/components/Global/Icons/IconSearch";
import TimeSince from "@/components/Global/TimeSince";
import IconInfoCircle from "@/components/Global/Icons/IconInfoCircle";
import PopoverPCPInfo from "@/components/Tasks/PopoverPcpInfo";
import ModalTaskDescription from "@/components/Tasks/ModalTaskDescription";
import { EventBus } from "@/services/Events";
import { send as httpSend } from "@/services/Api";

export default {
  name: "Tasks",
  props: {
    currentPatient: Object
  },
  data() {
    return {
      isPageLoading: false,
      isTableLoading: false,
      showOpenTasks: true,
      taskMode: "open",
      patientSearchString: undefined,
      openTasksFilters: "created,assigned,in_progress",
      closedTasksFilters: "blocked,canceled,completed",
      singleStatusFilter: "default",
      programsModel: "default",
      programs: [],
      serverParams: {
        id: "0",
        page: "1",
        perPage: "10",
        columnFilters: {
          status: "created,assigned,in_progress",
          search: null,
          beneficiary: this.currentPatient.payload.beneficiary.id,
          program: "",
          organization: "",
          provider: "",
          assignedTo: "",
          vip: ""
        },
        sort: []
      },
      columns: [
        {
          label: "Task Title",
          field: "name",
          thClass: "invisible",
          width: "350px",
          sortable: false
        },
        {
          label: "Assigned To",
          field: "assignedTo",
          formatFn: this.formatCompleted,
          width: "160px",
          sortable: true
        },
        {
          label: "Time Open",
          field: "createdAt",
          width: "160px",
          sortable: true
        },
        {
          label: "Status",
          field: "status",
          width: "135px",
          sortable: true
        },
        {
          label: "Created",
          field: "createdAt",
          width: "150px",
          sortable: true
        },
        {
          label: "Task ID",
          field: "taskId",
          width: "80px",
          sortable: true
        }
      ],
      rows: [],
      totalRecords: 10,
      rowClickDisabled: false
    };
  },
  components: {
    IconSearch,
    TimeSince,
    IconInfoCircle,
    IconCheckCircle
  },
  async mounted() {
    this.getPrograms();
  },
  computed: {
    currentProfessional() {
      return this.$store.getters["currentProfessional/get"];
    },
    hasTasks() {
      return !this.isLoading && this.rows.length > 0;
    }
  },
  created() {
    this.getTasks(this.serverParams, true, false);

    EventBus.$on("getTasks", () => {
      this.getTasks(this.serverParams, false, true);
    });
    EventBus.$on("taskAssigned", (taskId, professional) => {
      this.updateAssignedTo(taskId, professional.id, professional);
    });
    EventBus.$on("tasks_clean_url", () => {
      window.history.replaceState(null, null, window.location.pathname);
    });
  },
  methods: {
    async getTasks(params, showPageLoading, showTableLoading) {
      showPageLoading ? (this.isPageLoading = true) : "";
      showTableLoading ? (this.isTableLoading = true) : "";
      try {
        const fullPayload = await GetTasksList(params);
        this.rows = fullPayload.results;
        this.totalRecords = fullPayload.totalRecords;
      } finally {
        this.isPageLoading = false;
        this.isTableLoading = false;
      }
    },
    debounceSearch: _.debounce(function(evt, paramName) {
      this.serverParams.columnFilters[paramName] = evt.target.value;
      this.serverParams.page = 1;
      this.getTasks(this.serverParams, false, true);
    }, 500),
    checkboxClick: function(task) {
      this.rowClickDisabled = true;
      if (task.status == "Completed") {
        this.updateStatus(task.id, "in_progress");
      } else {
        this.updateStatus(task.id, "completed");
      }
    },
    async updateStatus(taskId, value) {
      this.isTableLoading = true;
      const data = {
        id: taskId,
        action: "status",
        payload: {
          status: value
        },
        callback: this.showToast
      };
      await this.$store.dispatch("tasks/updateSingleTask", data);
      this.getTasks(this.serverParams, false, true);
    },
    makeAssignedName(rowObj) {
      let title = rowObj.title ? rowObj.title + " " : "";
      let fName = rowObj.firstName ? rowObj.firstName + " " : "";
      let lName = rowObj.lastName ? rowObj.lastName : "";
      return title + fName + lName;
    },
    onRowClick(params) {
      if (!this.rowClickDisabled) {
        this.openDescriptionModal(params.row.task.id);
      }
      this.rowClickDisabled = false;
    },
    filterByStatus(evt) {
      this.$refs["vgt-table"] ? this.$refs["vgt-table"].reset() : "";
      this.serverParams.page = 1;
      if (evt == "all") {
        this.serverParams.columnFilters["status"] = "";
        this.taskMode = "all";
      } else if (evt == this.openTasksFilters) {
        this.serverParams.columnFilters["status"] = evt;
        this.singleStatusFilter = "default";
        // this.programsModel = "default";
        this.taskMode = "open";
      } else if (evt == this.closedTasksFilters) {
        this.serverParams.columnFilters["status"] = evt;
        this.singleStatusFilter = "default";
        // this.programsModel = "default";
        this.taskMode = "closed";
      } else if (evt.target.value == "all") {
        this.serverParams.columnFilters["status"] = "";
        this.taskMode = "all";
      } else if (evt.target.value == "created" || evt.target.value == "assigned" || evt.target.value == "in_progress") {
        this.serverParams.columnFilters["status"] = evt.target.value;
        this.taskMode = "open";
      } else if (evt.target.value == "canceled" || evt.target.value == "completed") {
        this.serverParams.columnFilters["status"] = evt.target.value;
        this.taskMode = "closed";
      }
      this.getTasks(this.serverParams, false, true);
    },
    filterByProgram(evt) {
      this.$refs["vgt-table"] ? this.$refs["vgt-table"].reset() : "";
      this.serverParams.page = 1;
      // this.taskMode = "all";
      this.serverParams.columnFilters["program"] = evt.target.value;
      this.getTasks(this.serverParams, false, true);
    },
    filter() {
      this.$refs["vgt-table"] ? this.$refs["vgt-table"].reset() : "";
      this.serverParams.page = 1;
    },
    onPageChange(params) {
      this.serverParams.page = params.currentPage;
      this.getTasks(this.serverParams, false, true);
    },
    onPerPageChange(params) {
      this.serverParams.perPage = params.currentPerPage;
      this.getTasks(this.serverParams, false, true);
    },
    onSortChange(params) {
      this.serverParams.sort = [{ field: params[0].field, type: params[0].type }];
      this.getTasks(this.serverParams, false, true);
    },
    openPatientInfoPopover(ev, rowObj) {
      this.rowClickDisabled = true;
      this.$ionic.popoverController
        .create({
          component: PopoverPCPInfo,
          event: ev,
          mode: "ios",
          componentProps: {
            data: {
              propData: rowObj
            }
          }
        })
        .then(m => m.present());
    },
    openDescriptionModal: async function(id) {
      this.$ionic.modalController
        .create({
          component: ModalTaskDescription,
          cssClass: "prime-modal-xwide",
          mode: "ios",
          componentProps: {
            propsData: {
              taskId: id
            }
          }
        })
        .then(m => m.present());
    },
    showYellowTimeElapsedPill(task) {
      const isActive = task.status == "created" || task.status == "assigned" || task.status == "in_progress";
      return task !== null && isActive;
    },
    makeStatus(status) {
      let rtnStatus = undefined;
      switch (status) {
        case "created":
          rtnStatus = "New";
          break;
        case "assigned":
          rtnStatus = "Assigned";
          break;
        case "in_progress":
          rtnStatus = "In Progress";
          break;
        case "canceled":
          rtnStatus = "Canceled";
          break;
        case "completed":
          rtnStatus = "Completed";
          break;
        default:
          return "Unknown";
      }
      return rtnStatus;
    },
    async updateAssignedTo(taskId, profId) {
      this.isLoadingAssignedTo = true;
      const data = {
        id: taskId,
        action: "assignto",
        payload: {
          assignedToProfessionalId: profId
        },
        callback: this.showToast
      };
      await this.$store.dispatch("tasks/updateSingleTask", data);
      this.getTasks(this.serverParams, false, true);
    },
    async getPrograms() {
      const method = "get";
      const path = document.config.programsApi;
      this.pageIsLoading = true;

      httpSend({ path, method, authToken: true })
        .then(response => {
          let rtnArr = response.data;
          const programsArr = [];
          if (rtnArr.length > 0) {
            // translate Text Triage into No Program per requirements
            for (var i = 0; i < rtnArr.length; i++) {
              programsArr.push(rtnArr[i].programs);
            }
            for (var i2 = 0; i2 < programsArr.length; i2++) {
              this.programs.push(programsArr[i2]);

              if (this.programs[i2].id == 1) {
                this.programs[i2].name = "No Program";
              }
            }

            this.programs = programsArr.flat();
          }

          this.pageIsLoading = false;
        })
        .catch(error => {
          this.pageIsLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load programs",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>

<style scoped>
/* -------------------------------- */
/* Tasks View --------------------- */
/* -------------------------------- */

.prime-tasks-empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-grow: 1;
}

.prime-tasks-empty-container ion-spinner {
  width: 200px;
  background: var(--ion-color-light);
  border-radius: 6px;
}

.prime-tasks-empty-container .prime-tasks-empty-icon,
.prime-tasks-empty-container h5 {
  opacity: 0.1;
}

.prime-tasks-section-list {
  margin-bottom: 50px;
}
.prime-tasks-section-header {
  margin-bottom: 10px;
}
.prime-tasks-section-header h5 {
  margin: 0 0 0 10px !important;
}
.prime-tasks-section-header .prime-tasks-column-header {
  font-size: 14px;
  color: var(--ion-color-medium-shade);
  text-align: center;
}
.prime-tasks-section-header h5.prime-overdue-section-header {
  color: white;
  background: var(--ion-color-danger-tint);
  display: inline-block;
  padding: 2px 10px;
  border-radius: 6px 6px 0 0;
  border-bottom: 8px solid var(--ion-color-danger-tint);
  margin-bottom: -32px !important;
  margin-left: 10px !important;
}

/* Tasks Columns Set Width ---------------- */
.prime-tasks-column-created {
  max-width: 80px;
  min-width: 80px;
}
.prime-tasks-column-due-date {
  min-width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}
.prime-tasks-column-button {
  max-width: 40px;
  min-width: 40px;
}
.prime-tasks-column-assigned-to {
  min-width: 180px;
  flex-shrink: 0;
}

/* Due Date Input ---------------- */
.prime-tasks-card input {
  margin-bottom: 0;
}
.prime-tasks-card .prime-tasks-due-date-input {
  border: none;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  color: var(--ion-color-primary);
  cursor: pointer;
  padding: 7px 5px 7px;
}
.prime-tasks-card .prime-tasks-due-date-input:hover,
.prime-tasks-card .prime-tasks-due-date-input:focus {
  border: none;
  outline: none;
  background: var(--ion-color-light);
}
.vc-highlight {
  background: var(--ion-color-primary);
}
.vc-container {
  font-family: var(--ion-font-family);
}
.icon-check-circle-watermark {
  width: 140px;
}

/* vgt overrides */

.vgt-responsive {
  font-size: 14px;
  border-collapse: collapse;
  background-color: rgba(255, 255, 255, 0) !important;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border: 1px solid;
}

table.vgt-table tr.clickable {
  cursor: pointer;
}

table.vgt-table tr.clickable:hover {
  background-color: var(--ion-color-light);
}

.vgt-table th {
  padding: 10px 5px;
  vertical-align: middle;
  position: relative;
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  user-select: none;
  font-size: 13px;
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

/* task card styling */
.prime-tasks-title-container {
  padding-left: 10px;
  display: flex;
  align-items: center;
}
.prime-tasks-title-container .prime-tasks-task-title-input {
  margin-left: 15px;
  width: 100%;
  height: 34px;
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 6px 15px 7px 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  transition: border 0.2s ease-in-out;
}
.prime-tasks-title-container .prime-tasks-task-title-input:hover {
  background: var(--ion-color-light);
  border: 2px solid var(--ion-color-light);
  cursor: pointer;
}
.prime-tasks-title-container .prime-tasks-task-title-input:focus {
  background: #ffffff;
  border: 2px solid var(--ion-color-primary);
  cursor: initial;
}

/* Task Checkbox ---------------- */
.prime-task-checkbox {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.prime-task-checkbox div {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  cursor: pointer;
}
.prime-task-checkbox div::before,
.prime-task-checkbox div::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.prime-task-checkbox div::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-medium-shade);
  border-radius: 20px;
  background: #ffffff;
  cursor: pointer;
}
.prime-task-checkbox div:hover::before {
  border: 2px solid var(--ion-color-primary);
}
.prime-task-checkbox div::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.prime-task-checkbox.checked div:before {
  background: var(--ion-color-primary);
  border: 2px solid var(--ion-color-primary);
}

.prime-task-checkbox.checkedGrey div:before {
  background: var(--ion-color-medium-shade);
  border: 2px solid var(--ion-color-medium-shade);
}

.prime-tasks-section-container {
  position: relative;
}
</style>
